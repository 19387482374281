import { ReactElement } from 'react';
import { Translation } from 'react-i18next';

export const AUTH_KEY = 'CONSOLE_AUTH';

export const ACTIVE_ACCOUNT_ID = 'Console-account-id';
export const ACTIVE_PROJECT_ID = 'Console-project-id';
export const ACTIVE_USER = 'Console-user';
export const ACTIVE_PROJECT_NAME = 'Console-project-name';
export const ACTIVE_ACCOUNT_NAME = 'Console-account-name';

export const KNOWLEDGE_INTRO = 'Console-knowledge-intro';

export const LAST_TIMESTAMP = 'Console-lastTimeStamp';

export const CONSOLE_CHAT_ID = 'console-chat';

export const initialLDContext = {
  kind: 'multi',
  user: {
    anonymous: true,
  },
  account: {
    anonymous: true,
  },
  project: {
    anonymous: true,
  },
};

export const currencies = [
  {
    code: 'usd',
    symbol: '$',
  },
  {
    code: 'eur',
    symbol: '€',
  },
  {
    code: 'gbp',
    symbol: '£',
  },
  {
    code: 'sgd',
    symbol: '$',
  },
] as const;
export type CurrencyCode = typeof currencies[number]['code'];
export type CurrencySymbol = typeof currencies[number]['symbol'];

export type Weekday =
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | 'sunday';

export const days: Record<Weekday, ReactElement> = {
  monday: <Translation>{t => t('monday')}</Translation>,
  tuesday: <Translation>{t => t('tuesday')}</Translation>,
  wednesday: <Translation>{t => t('wednesday')}</Translation>,
  thursday: <Translation>{t => t('thursday')}</Translation>,
  friday: <Translation>{t => t('friday')}</Translation>,
  saturday: <Translation>{t => t('saturday')}</Translation>,
  sunday: <Translation>{t => t('sunday')}</Translation>,
};
export const dayOrder: Weekday[] = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

export type Period = 'week' | 'month';

export const CHANNELS = [
  {
    text: 'Web',
    value: 'web',
  },
  {
    text: 'FAQ',
    value: 'faq',
  },
  {
    text: 'Messenger',
    value: 'fb_messenger',
  },
  {
    text: 'WhatsApp',
    value: 'whatsapp',
  },
  {
    text: 'SMS',
    value: 'sms',
  },
  {
    text: 'Teams',
    value: 'ms_teams',
  },
  {
    text: 'Voice',
    value: 'voice',
  },
];

export const STATUSES = [
  {
    text: <Translation ns={'knowledge'}>{t => t('published')}</Translation>,
    value: 'success',
  },
  {
    text: <Translation ns={'knowledge'}>{t => t('publishing')}</Translation>,
    value: 'in_progress',
  },
  {
    text: <Translation ns={'knowledge'}>{t => t('missing')}</Translation>,
    value: 'missing',
  },
  {
    text: <Translation ns={'knowledge'}>{t => t('draft')}</Translation>,
    value: 'draft',
  },
  {
    text: <Translation ns={'knowledge'}>{t => t('failed')}</Translation>,
    value: 'failed',
  },
];

export const PROVIDERS = [
  {
    text: 'Dialogflow CX',
    value: 'dialogflow_cx',
  },
  {
    text: 'Dialogflow ES',
    value: 'dialogflow_es',
  },
];

export const VAFonts = [
  {
    option: 'Lato',
    value: "'Lato', sans-serif",
  },
  {
    option: 'Open Sans',
    value: "'Open Sans', sans-serif",
  },
  {
    option: 'Roboto',
    value: "'Roboto', sans-serif",
  },
  {
    option: 'Montserrat',
    value: "'Montserrat', sans-serif",
  },
  {
    option: 'PT Sans',
    value: "'PT Sans', sans-serif",
  },
];

export const VAButtonType = [
  { option: <Translation ns={'design'}>{t => t('outline')}</Translation>, value: 'outline' },
  { option: <Translation ns={'design'}>{t => t('filled')}</Translation>, value: 'filled' },
];

export const TRANSLATION_STATUS_LIST = ['missing', 'verified', 'unverified'];

export const KnowledgeType = [
  { label: 'FAQ', value: 'FAQ' },
  { label: 'CSV', value: 'CSV', disabled: true },
  { label: 'Extractive QA', value: 'EXTRACTIVE_QA' },
  { label: 'Article Suggestion', value: 'ARTICLE_SUGGESTION', disabled: true },
];

export const TimeUnits = [
  { label: <Translation ns={'ai'}>{t => t('day')}</Translation>, value: 'day' },
  { label: <Translation ns={'ai'}>{t => t('month')}</Translation>, value: 'month' },
];

export const DataRetentionType = [
  { label: 'Interactions', value: 'interactions' },
  { label: 'Metrics', value: 'metrics' },
  { label: 'Voice Recordings', value: 'voice_recordings' },
];

export const TimezoneByCity = [
  { zone: 'Pacific/Pago_Pago', value: 'American Samoa', label: 'American Samoa' },
  {
    zone: 'Pacific/Kwajalein',
    value: 'International Date Line West',
    label: 'International Date Line West',
  },
  { zone: 'Pacific/Midway', value: 'Midway Island', label: 'Midway Island' },
  { zone: 'Pacific/Honolulu', value: 'Hawaii', label: 'Hawaii' },
  { zone: 'America/Anchorage', value: 'Alaska', label: 'Alaska' },
  {
    zone: 'America/Los_Angeles',
    value: 'Pacific Time (US & Canada)',
    label: 'Pacific Time (US & Canada)',
  },
  { zone: 'America/Tijuana', value: 'Tijuana', label: 'Tijuana' },
  { zone: 'America/Phoenix', value: 'Arizona', label: 'Arizona' },
  { zone: 'America/Chihuahua', value: 'Chihuahua', label: 'Chihuahua' },
  { zone: 'America/Mazatlan', value: 'Mazatlan', label: 'Mazatlan' },
  {
    zone: 'America/Denver',
    value: 'Mountain Time (US & Canada)',
    label: 'Mountain Time (US & Canada)',
  },
  { zone: 'America/Guatemala', value: 'Central America', label: 'Central America' },
  {
    zone: 'America/Chicago',
    value: 'Central Time (US & Canada)',
    label: 'Central Time (US & Canada)',
  },
  { zone: 'America/Guadalajara', value: 'Guadalajara', label: 'Guadalajara' },
  { zone: 'America/Mexico_City', value: 'Mexico City', label: 'Mexico City' },
  { zone: 'America/Monterrey', value: 'Monterrey', label: 'Monterrey' },
  { zone: 'America/Regina', value: 'Saskatchewan', label: 'Saskatchewan' },
  { zone: 'America/Bogota', value: 'Bogota', label: 'Bogota' },
  {
    zone: 'America/New_York',
    value: 'Eastern Time (US & Canada)',
    label: 'Eastern Time (US & Canada)',
  },
  { zone: 'America/Indianapolis', value: 'Indiana (East)', label: 'Indiana (East)' },
  { zone: 'America/Lima', value: 'Lima', label: 'Lima' },
  { zone: 'America/Quito', value: 'Quito', label: 'Quito' },
  { zone: 'America/Halifax', value: 'Atlantic Time (Canada)', label: 'Atlantic Time (Canada)' },
  { zone: 'America/Caracas', value: 'Caracas', label: 'Caracas' },
  { zone: 'America/Guyana', value: 'Georgetown', label: 'Georgetown' },
  { zone: 'America/La_Paz', value: 'La Paz', label: 'La Paz' },
  { zone: 'America/Santiago', value: 'Santiago', label: 'Santiago' },
  { zone: 'America/St_Johns', value: 'Newfoundland', label: 'Newfoundland' },
  { zone: 'America/Brasilia', value: 'Brasilia', label: 'Brasilia' },
  { zone: 'America/Argentina/Buenos_Aires', value: 'Buenos Aires', label: 'Buenos Aires' },
  { zone: 'America/Nuuk', value: 'Greenland', label: 'Greenland' },
  { zone: 'America/Montevideo', value: 'Montevideo', label: 'Montevideo' },
  { zone: 'Atlantic/Reykjavik', value: 'Mid-Atlantic', label: 'Mid-Atlantic' },
  { zone: 'Atlantic/Azores', value: 'Azores', label: 'Azores' },
  { zone: 'Atlantic/Cape_Verde', value: 'Cape Verde Is.', label: 'Cape Verde Is.' },
  { zone: 'Europe/London', value: 'Edinburgh', label: 'Edinburgh' },
  { zone: 'Europe/Lisbon', value: 'Lisbon', label: 'Lisbon' },
  { zone: 'Europe/London', value: 'London', label: 'London' },
  { zone: 'Africa/Monrovia', value: 'Monrovia', label: 'Monrovia' },
  { zone: 'UTC', value: 'UTC', label: 'UTC' },
  { zone: 'Europe/Amsterdam', value: 'Amsterdam', label: 'Amsterdam' },
  { zone: 'Europe/Belgrade', value: 'Belgrade', label: 'Belgrade' },
  { zone: 'Europe/Berlin', value: 'Berlin', label: 'Berlin' },
  { zone: 'Europe/Zurich', value: 'Bern', label: 'Bern' },
  { zone: 'Europe/Bratislava', value: 'Bratislava', label: 'Bratislava' },
  { zone: 'Europe/Brussels', value: 'Brussels', label: 'Brussels' },
  { zone: 'Europe/Budapest', value: 'Budapest', label: 'Budapest' },
  { zone: 'Africa/Casablanca', value: 'Casablanca', label: 'Casablanca' },
  { zone: 'Europe/Copenhagen', value: 'Copenhagen', label: 'Copenhagen' },
  { zone: 'Europe/Dublin', value: 'Dublin', label: 'Dublin' },
  { zone: 'Europe/Ljubljana', value: 'Ljubljana', label: 'Ljubljana' },
  { zone: 'Europe/Madrid', value: 'Madrid', label: 'Madrid' },
  { zone: 'Europe/Paris', value: 'Paris', label: 'Paris' },
  { zone: 'Europe/Prague', value: 'Prague', label: 'Prague' },
  { zone: 'Europe/Rome', value: 'Rome', label: 'Rome' },
  { zone: 'Europe/Sarajevo', value: 'Sarajevo', label: 'Sarajevo' },
  { zone: 'Europe/Skopje', value: 'Skopje', label: 'Skopje' },
  { zone: 'Europe/Stockholm', value: 'Stockholm', label: 'Stockholm' },
  { zone: 'Europe/Vienna', value: 'Vienna', label: 'Vienna' },
  { zone: 'Europe/Warsaw', value: 'Warsaw', label: 'Warsaw' },
  { zone: 'Africa/Lagos', value: 'West Central Africa', label: 'West Central Africa' },
  { zone: 'Europe/Zagreb', value: 'Zagreb', label: 'Zagreb' },
  { zone: 'Europe/Zurich', value: 'Zurich', label: 'Zurich' },
  { zone: 'Europe/Athens', value: 'Athens', label: 'Athens' },
  { zone: 'Europe/Bucharest', value: 'Bucharest', label: 'Bucharest' },
  { zone: 'Africa/Cairo', value: 'Cairo', label: 'Cairo' },
  { zone: 'Africa/Harare', value: 'Harare', label: 'Harare' },
  { zone: 'Europe/Helsinki', value: 'Helsinki', label: 'Helsinki' },
  { zone: 'Asia/Jerusalem', value: 'Jerusalem', label: 'Jerusalem' },
  { zone: 'Europe/Kaliningrad', value: 'Kaliningrad', label: 'Kaliningrad' },
  { zone: 'Europe/Kyiv', value: 'Kyiv', label: 'Kyiv' },
  { zone: 'Africa/Johannesburg', value: 'Pretoria', label: 'Pretoria' },
  { zone: 'Europe/Riga', value: 'Riga', label: 'Riga' },
  { zone: 'Europe/Sofia', value: 'Sofia', label: 'Sofia' },
  { zone: 'Europe/Tallinn', value: 'Tallinn', label: 'Tallinn' },
  { zone: 'Europe/Vilnius', value: 'Vilnius', label: 'Vilnius' },
  { zone: 'Asia/Baghdad', value: 'Baghdad', label: 'Baghdad' },
  { zone: 'Europe/Istanbul', value: 'Istanbul', label: 'Istanbul' },
  { zone: 'Asia/Kuwait', value: 'Kuwait', label: 'Kuwait' },
  { zone: 'Europe/Minsk', value: 'Minsk', label: 'Minsk' },
  { zone: 'Europe/Moscow', value: 'Moscow', label: 'Moscow' },
  { zone: 'Africa/Nairobi', value: 'Nairobi', label: 'Nairobi' },
  { zone: 'Asia/Riyadh', value: 'Riyadh', label: 'Riyadh' },
  { zone: 'Europe/St_Petersburg', value: 'St. Petersburg', label: 'St. Petersburg' },
  { zone: 'Asia/Tehran', value: 'Tehran', label: 'Tehran' },
  { zone: 'Asia/Dubai', value: 'Abu Dhabi', label: 'Abu Dhabi' },
  { zone: 'Asia/Baku', value: 'Baku', label: 'Baku' },
  { zone: 'Asia/Muscat', value: 'Muscat', label: 'Muscat' },
  { zone: 'Europe/Samara', value: 'Samara', label: 'Samara' },
  { zone: 'Asia/Tbilisi', value: 'Tbilisi', label: 'Tbilisi' },
  { zone: 'Europe/Volgograd', value: 'Volgograd', label: 'Volgograd' },
  { zone: 'Asia/Yerevan', value: 'Yerevan', label: 'Yerevan' },
  { zone: 'Asia/Kabul', value: 'Kabul', label: 'Kabul' },
  { zone: 'Asia/Yekaterinburg', value: 'Ekaterinburg', label: 'Ekaterinburg' },
  { zone: 'Asia/Karachi', value: 'Islamabad', label: 'Islamabad' },
  { zone: 'Asia/Karachi', value: 'Karachi', label: 'Karachi' },
  { zone: 'Asia/Tashkent', value: 'Tashkent', label: 'Tashkent' },
  { zone: 'Asia/Kolkata', value: 'Chennai', label: 'Chennai' },
  { zone: 'Asia/Kolkata', value: 'Kolkata', label: 'Kolkata' },
  { zone: 'Asia/Kolkata', value: 'Mumbai', label: 'Mumbai' },
  { zone: 'Asia/Kolkata', value: 'New Delhi', label: 'New Delhi' },
  { zone: 'Asia/Colombo', value: 'Sri Jayawardenepura', label: 'Sri Jayawardenepura' },
  { zone: 'Asia/Kathmandu', value: 'Kathmandu', label: 'Kathmandu' },
  { zone: 'Asia/Almaty', value: 'Almaty', label: 'Almaty' },
  { zone: 'Asia/Almaty', value: 'Astana', label: 'Astana' },
  { zone: 'Asia/Dhaka', value: 'Dhaka', label: 'Dhaka' },
  { zone: 'Asia/Urumqi', value: 'Urumqi', label: 'Urumqi' },
  { zone: 'Asia/Yangon', value: 'Rangoon', label: 'Rangoon' },
  { zone: 'Asia/Bangkok', value: 'Bangkok', label: 'Bangkok' },
  { zone: 'Asia/Ho_Chi_Minh', value: 'Hanoi', label: 'Hanoi' },
  { zone: 'Asia/Jakarta', value: 'Jakarta', label: 'Jakarta' },
  { zone: 'Asia/Krasnoyarsk', value: 'Krasnoyarsk', label: 'Krasnoyarsk' },
  { zone: 'Asia/Novosibirsk', value: 'Novosibirsk', label: 'Novosibirsk' },
  { zone: 'Asia/Shanghai', value: 'Beijing', label: 'Beijing' },
  { zone: 'Asia/Shanghai', value: 'Chongqing', label: 'Chongqing' },
  { zone: 'Asia/Hong_Kong', value: 'Hong Kong', label: 'Hong Kong' },
  { zone: 'Asia/Irkutsk', value: 'Irkutsk', label: 'Irkutsk' },
  { zone: 'Asia/Kuala_Lumpur', value: 'Kuala Lumpur', label: 'Kuala Lumpur' },
  { zone: 'Australia/Perth', value: 'Perth', label: 'Perth' },
  { zone: 'Asia/Singapore', value: 'Singapore', label: 'Singapore' },
  { zone: 'Asia/Taipei', value: 'Taipei', label: 'Taipei' },
  { zone: 'Asia/Ulaanbaatar', value: 'Ulaanbaatar', label: 'Ulaanbaatar' },
  { zone: 'Asia/Tokyo', value: 'Osaka', label: 'Osaka' },
  { zone: 'Asia/Tokyo', value: 'Sapporo', label: 'Sapporo' },
  { zone: 'Asia/Seoul', value: 'Seoul', label: 'Seoul' },
  { zone: 'Asia/Tokyo', value: 'Tokyo', label: 'Tokyo' },
  { zone: 'Asia/Yakutsk', value: 'Yakutsk', label: 'Yakutsk' },
  { zone: 'Australia/Adelaide', value: 'Adelaide', label: 'Adelaide' },
  { zone: 'Australia/Darwin', value: 'Darwin', label: 'Darwin' },
  { zone: 'Australia/Brisbane', value: 'Brisbane', label: 'Brisbane' },
  { zone: 'Australia/Canberra', value: 'Canberra', label: 'Canberra' },
  { zone: 'Pacific/Guam', value: 'Guam', label: 'Guam' },
  { zone: 'Australia/Hobart', value: 'Hobart', label: 'Hobart' },
  { zone: 'Australia/Melbourne', value: 'Melbourne', label: 'Melbourne' },
  { zone: 'Pacific/Port_Moresby', value: 'Port Moresby', label: 'Port Moresby' },
  { zone: 'Australia/Sydney', value: 'Sydney', label: 'Sydney' },
  { zone: 'Asia/Vladivostok', value: 'Vladivostok', label: 'Vladivostok' },
  { zone: 'Asia/Magadan', value: 'Magadan', label: 'Magadan' },
  { zone: 'Pacific/Noumea', value: 'New Caledonia', label: 'New Caledonia' },
  { zone: 'Pacific/Guadalcanal', value: 'Solomon Is.', label: 'Solomon Is.' },
  { zone: 'Asia/Srednekolymsk', value: 'Srednekolymsk', label: 'Srednekolymsk' },
  { zone: 'Pacific/Auckland', value: 'Auckland', label: 'Auckland' },
  { zone: 'Pacific/Fiji', value: 'Fiji', label: 'Fiji' },
  { zone: 'Asia/Kamchatka', value: 'Kamchatka', label: 'Kamchatka' },
  { zone: 'Pacific/Majuro', value: 'Marshall Is.', label: 'Marshall Is.' },
  { zone: 'Pacific/Auckland', value: 'Wellington', label: 'Wellington' },
  { zone: 'Pacific/Chatham', value: 'Chatham Is.', label: 'Chatham Is.' },
  { zone: 'Pacific/Tongatapu', value: "Nuku'alofa", label: "Nuku'alofa" },
  { zone: 'Pacific/Apia', value: 'Samoa', label: 'Samoa' },
  { zone: 'Pacific/Tokelau', value: 'Tokelau Is.', label: 'Tokelau Is.' },
];

export const TimeState = [
  { label: <Translation ns={'ai'}>{t => t('preferred_time')}</Translation>, value: false },
  { label: <Translation ns={'ai'}>{t => t('all_day')}</Translation>, value: true },
];

export const ChannelTypesLabel = [
  { label: <Translation ns={'ai'}>{t => t('channel_web')}</Translation>, value: 'web' },
  { label: <Translation ns={'ai'}>{t => t('channel_voice')}</Translation>, value: 'voice' },
  { label: <Translation ns={'ai'}>{t => t('channel_messenger')}</Translation>, value: 'facebook' },
  { label: <Translation ns={'ai'}>{t => t('channel_sms')}</Translation>, value: 'sms' },
  { label: <Translation ns={'ai'}>{t => t('channel_teams')}</Translation>, value: 'teams' },
  { label: <Translation ns={'ai'}>{t => t('channel_whatsapp')}</Translation>, value: 'whatsapp' },
];

export const ChannelIdentifierFields = [
  {
    channel: 'web',
    placeholder: 'enter_identifier',
    tooltip: 'identifier_web_tooltip',
  },
  {
    channel: 'voice',
    placeholder: 'enter_phone_number',
    tooltip: 'identifier_voice_tooltip',
  },
  {
    channel: 'facebook',
    placeholder: 'enter_identifier',
    tooltip: 'identifier_messenger_tooltip',
  },
  {
    channel: 'sms',
    placeholder: 'enter_phone_number',
    tooltip: 'identifier_voice_tooltip',
  },
  {
    channel: 'teams',
    placeholder: 'enter_phone_number',
    tooltip: 'identifier_voice_tooltip',
  },
  {
    channel: 'whatsapp',
    placeholder: 'enter_phone_number',
    tooltip: 'identifier_voice_tooltip',
  },
];

export const SuggestionFeatureType = [
  { label: 'Dialogflow Assist', value: 'DIALOGFLOW_ASSIST' },
  { label: 'FAQ', value: 'FAQ' },
];

export const HumanAgentFieldsReset = [
  {
    path: [
      'human_agent_assistant_config',
      'human_agent_suggestion_config',
      'feature_configs',
      0,
      'suggestion_feature',
      'type',
    ],
    value: undefined,
  },
  {
    path: [
      'human_agent_assistant_config',
      'human_agent_suggestion_config',
      'feature_configs',
      0,
      'enable_inline_suggestion',
    ],
    value: false,
  },
  {
    path: [
      'human_agent_assistant_config',
      'human_agent_suggestion_config',
      'feature_configs',
      0,
      'enable_event_based_suggestion',
    ],
    value: false,
  },
  {
    path: [
      'human_agent_assistant_config',
      'human_agent_suggestion_config',
      'group_suggestion_responses',
    ],
    value: false,
  },
  {
    path: [
      'human_agent_assistant_config',
      'human_agent_suggestion_config',
      'feature_configs',
      0,
      'query_config',
      'knowledge_base_query_source',
      'knowledge_bases',
      0,
    ],
    value: undefined,
  },
];

export const RouterProtolcolTypes = [
  { label: 'pstn', value: 'pstn' },
  { label: 'sip', value: 'sip' },
];

export const GenesysLocations = [
  { label: 'us-east-1', value: 'us-east-1' },
  { label: 'us-east-2', value: 'us-east-2' },
  { label: 'us-west-2', value: 'us-west-2' },
  { label: 'ca-central-1', value: 'ca-central-1' },
  { label: 'sa-east-1', value: 'sa-east-1' },
  { label: 'eu-central-1', value: 'eu-central-1' },
  { label: 'eu-west-1', value: 'eu-west-1' },
  { label: 'eu-west-2', value: 'eu-west-2' },
  { label: 'ap-south-1', value: 'ap-south-1' },
  { label: 'ap-southeast-2', value: 'ap-southeast-2' },
  { label: 'ap-northeast-1', value: 'ap-northeast-1' },
  { label: 'ap-northeast-2', value: 'ap-northeast-2' },
];

export const esLocales = [
  'af',
  'bg',
  'ca',
  'cs',
  'da',
  'de',
  'el',
  'en',
  'en-AU',
  'en-CA',
  'en-GB',
  'en-IN',
  'en-US',
  'es',
  'es-ES',
  'fr-CA',
  'fr-FR',
  'gu',
  'hi',
  'hu',
  'is',
  'id',
  'it',
  'ja',
  'kn',
  'ko',
  'lv',
  'ml',
  'ms',
  'ms-MY',
  'no',
  'nl',
  'pl',
  'pt',
  'pt-BR',
  'ru',
  'sr',
  'sk',
  'sv',
  'tr',
  'uk',
  'zh-CN',
  'zh-HK',
];

export const cxLocales = [
  'af',
  'ar',
  'bn',
  'bn-BD',
  'bn-IN',
  'bg',
  'ca',
  'cs',
  'da',
  'nl',
  'zh-HK',
  'zh-CN',
  'en',
  'en-AU',
  'en-CA',
  'en-GB',
  'en-IN',
  'en-US',
  'fi',
  'fil',
  'fil-PH',
  'fr-CA',
  'fr-FR',
  'de',
  'el',
  'gu',
  'hi',
  'hu',
  'is',
  'id',
  'it',
  'ja',
  'kn',
  'ko',
  'lv',
  'ms',
  'ms-MY',
  'ml',
  'mr',
  'mr-IN',
  'no',
  'pl',
  'pt',
  'pt-BR',
  'ro',
  'ro-RO',
  'ru',
  'sr',
  'sk',
  'es',
  'es-ES',
  'sv',
  'ta',
  'ta-IN',
  'ta-LK',
  'ta-MY',
  'ta-SG',
  'te',
  'te-IN',
  'th',
  'tr',
  'uk',
  'vi',
  'vi-VN',
];

export const speechModelOptions = [
  { label: <Translation ns={'ai'}>{t => t('latest_long')}</Translation>, value: 'latest_long' },
  { label: <Translation ns={'ai'}>{t => t('latest_short')}</Translation>, value: 'latest_short' },
  {
    label: <Translation ns={'ai'}>{t => t('command_and_search')}</Translation>,
    value: 'command_and_search',
  },
  { label: <Translation ns={'ai'}>{t => t('phone_call')}</Translation>, value: 'phone_call' },
  { label: <Translation ns={'ai'}>{t => t('video')}</Translation>, value: 'video' },
  { label: <Translation ns={'ai'}>{t => t('default')}</Translation>, value: 'default' },
  {
    label: <Translation ns={'ai'}>{t => t('medical_conversation')}</Translation>,
    value: 'medical_conversation',
  },
  {
    label: <Translation ns={'ai'}>{t => t('medical_dictation')}</Translation>,
    value: 'medical_dictation',
  },
];

export const speechModelVariantOptions = [
  {
    label: <Translation ns={'ai'}>{t => t('unspecified')}</Translation>,
    value: 'SPEECH_MODEL_VARIANT_UNSPECIFIED',
  },
  {
    label: <Translation ns={'ai'}>{t => t('use_best_available')}</Translation>,
    value: 'USE_BEST_AVAILABLE',
  },
  {
    label: <Translation ns={'ai'}>{t => t('use_standard')}</Translation>,
    value: 'USE_STANDARD',
  },
  { label: <Translation ns={'ai'}>{t => t('use_enhanced')}</Translation>, value: 'USE_ENHANCED' },
];
