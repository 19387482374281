import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { Content } from '../../../../components/Content';
import { StyledCard, CardInfo } from '../../AI.styles';
import { Paths } from '../../../../types/paths';
import { ViewLink } from '../../../../components/ViewLink';

export const AutomationList: React.FC = () => {
  const { t } = useTranslation('ai');

  return (
    <Content imgBg={false}>
      <Row gutter={16}>
        <Col xs={24} sm={12} lg={8} xxl={6}>
          <StyledCard title={t('automation_profiles')} bordered={false}>
            <CardInfo>{t('automation_profiles_desc')}</CardInfo>
            <ViewLink to={Paths.aiAutomationProfiles()} />
          </StyledCard>
        </Col>
        <Col xs={24} sm={12} lg={8} xxl={6}>
          <StyledCard title={t('nlu_providers')} bordered={false}>
            <CardInfo>{t('nlu_providers_desc')}</CardInfo>
            <ViewLink to={Paths.aiNLUProviders()} />
          </StyledCard>
        </Col>
        <Col xs={24} sm={12} lg={8} xxl={6}>
          <StyledCard title={t('conversation_profiles')} bordered={false}>
            <CardInfo>{t('conversation_profiles_desc')}</CardInfo>
            <ViewLink to={Paths.aiConversationProfiles()} />
          </StyledCard>
        </Col>
        <Col xs={24} sm={12} lg={8} xxl={6}>
          <StyledCard title={t('text_to_speech_profiles')} bordered={false}>
            <CardInfo>{t('text_to_speech_profiles_desc')}</CardInfo>
            <ViewLink to={Paths.aiTextToSpeech()} />
          </StyledCard>
        </Col>
        <Col xs={24} sm={12} lg={8} xxl={6}>
          <StyledCard title={t('auto_resp_templates')} bordered={false}>
            <CardInfo>{t('auto_resp_templates_desc')}</CardInfo>
            <ViewLink to={Paths.aiAutoRespTemplates()} />
          </StyledCard>
        </Col>
        <Col xs={24} sm={12} lg={8} xxl={6}>
          <StyledCard title={t('contexts')} bordered={false}>
            <CardInfo>{t('contexts_desc')}</CardInfo>
            <ViewLink to={Paths.aiContexts()} />
          </StyledCard>
        </Col>
      </Row>
    </Content>
  );
};
