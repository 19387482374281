import { useState } from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { formItemClass } from '../../../../../../theme';
import { Space } from '../../../../../../components/Space';
import { DFAgentSelector } from '../../../../../../components/DFAgentSelector';
import { ConversationProfilesSelector } from '../../../../../../components/ConversationProfilesSelector';
import { DLPTemplateSelector } from '../../../../../../components/DLPTemplateSelector';
import { ContextsSelector } from '../../../../../../components/ContextsSelector';
import { ContextModal } from '../../../Contexts/ContextModal';
import { FallbackBehaviourSelector } from '../../../../../../components/FallbackBehaviourSelector';
import { FallbackBehaviourModal } from '../../../../Configuration/FallbackBehaviour/FallbackBehaviourModal';
import { IntegrationType } from '../../types';
import { DFAgentType } from '../../../../../../components/DFAgentTag';

interface Props {
  type: IntegrationType;
  platform: DFAgentType;
}

export const AutomationConfig = ({ type, platform }: Props): JSX.Element => {
  const { t } = useTranslation('ai');
  const [isVisibleContextModal, setVisibleContextModal] = useState<boolean>(false);
  const [createdContextKey, setCreatedContextKey] = useState(0);
  const [isVisibleBehaviourModal, setVisibleBehaviourModal] = useState<boolean>(false);
  const [createdBehaviourKey, setCreatedBehaviourKey] = useState(0);

  return (
    <>
      <Space>
        {type === 'standard' && (
          <Form.Item
            name="dialogflow_agent_id"
            label={t('dialogflow_agent')}
            className={formItemClass.base}
            rules={[{ required: true }]}
          >
            <DFAgentSelector placeholder={t('select_agent')} platformEdition={platform} />
          </Form.Item>
        )}
        {type === 'ccai' && (
          <Form.Item
            name="dialogflow_conversation_profile_id"
            label={t('conversation_profile')}
            className={formItemClass.base}
            rules={[{ required: true }]}
          >
            <ConversationProfilesSelector placeholder={t('select_conversation_profile')} />
          </Form.Item>
        )}
        <Form.Item name="dlp_config_id" label={t('dlp_template')} className={formItemClass.base}>
          <DLPTemplateSelector placeholder={t('select_dlp_template')} />
        </Form.Item>
      </Space>
      <Space>
        {platform === 'ES' && (
          <>
            <Form.Item name="context_ids" label={t('contexts')} className={formItemClass.base}>
              <ContextsSelector
                placeholder={t('select_context')}
                key={createdContextKey}
                onNewContext={setVisibleContextModal}
              />
            </Form.Item>
            <ContextModal
              isVisible={isVisibleContextModal}
              setVisible={setVisibleContextModal}
              setCreatedKey={setCreatedContextKey}
            />
          </>
        )}
        <Form.Item
          name="fallback_config_id"
          label={t('fallback_behav')}
          className={formItemClass.base}
        >
          <FallbackBehaviourSelector
            placeholder={t('select_fallback_behaviour')}
            key={createdBehaviourKey}
            onNewBehaviour={setVisibleBehaviourModal}
          />
        </Form.Item>
        <FallbackBehaviourModal
          isVisible={isVisibleBehaviourModal}
          setVisible={setVisibleBehaviourModal}
          setCreatedKey={setCreatedBehaviourKey}
        />
      </Space>
    </>
  );
};
