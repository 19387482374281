import React from 'react';
import { Navigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { RequireAuth } from '../state/auth';
import { Home } from '../pages/Home';
import { PageNotFound } from '../pages/PageNotFound/PageNotFound';
import { FairUsePolicy } from '../pages/FairUsePolicy';
import { TermsConditions } from '../pages/TermsConditions';
import { SecurityPolicy } from '../pages/SecurityPolicy';
import { Healthcheck } from '../pages/Healthcheck';
import { Wellbeing } from '../pages/Wellbeing';
import { Wellbeing as OldWellbeing } from '../pages/OldWellbeing';
import { accountRoutes } from '../pages/Accounts/routes';
import { userRoutes } from '../pages/Projects/Users/routes';
import { projectRoutes } from '../pages/Projects/routes';
import { userSettingsRoutes } from '../pages/UserSettings/routes';
import { aiIntegrationRoutes } from '../pages/AiIntegration/routes';
import { designRoutes } from '../pages/Design/routes';
import { designV1Routes } from '../pages/DesignV1/routes';
import { knowledgeRoutes } from '../pages/Knowledge/routes';
import { oldKnowledgeRoutes } from '../pages/OldKnowledge/routes';
import { analyticRoutes } from '../pages/Analytics/routes';
import { loginRoutes } from '../pages/Login/routes';
import { pulseRoutes } from '../pages/Pulse/routes';
import { aiRoutes } from '../pages/AI/routes';
import { oldAnalyticRoutes } from '../pages/OldAnalytics/routes';
import { Paths } from '../types/paths';

export const useRouteConfig = (authenticated: boolean) => {
  const { flexAnswerCustomer, switchToConsoleAnalytics } = useFlags();

  return [
    {
      path: '/',
      element: (
        <RequireAuth>
          <Home />
        </RequireAuth>
      ),
      children: [
        {
          index: true,
          // FlexAnswer customers need to be authenticated first by navigating to knowledge, to access fAReports in analytics
          element: (
            <Navigate
              replace
              to={
                flexAnswerCustomer
                  ? 'knowledge'
                  : switchToConsoleAnalytics
                  ? 'analytics'
                  : 'old-analytics'
              }
            />
          ),
          breadcrumb: null,
        },
        { ...aiIntegrationRoutes() },
        { ...accountRoutes() },
        { ...analyticRoutes() },
        { ...oldAnalyticRoutes() },
        { ...designRoutes() },
        { ...designV1Routes() },
        { ...oldKnowledgeRoutes() },
        { ...knowledgeRoutes() },
        { path: Paths.wellbeing(), element: <Wellbeing /> },
        { path: Paths.oldWellbeing(), element: <OldWellbeing /> },
        { ...userSettingsRoutes() },
        { ...userRoutes() },
        { ...projectRoutes() },
        { ...pulseRoutes() },
        { ...aiRoutes() },
      ],
    },
    { ...loginRoutes(authenticated, flexAnswerCustomer) },
    { path: Paths.termsConditions(), element: <TermsConditions /> },
    { path: Paths.fairUsePolicy(), element: <FairUsePolicy /> },
    { path: Paths.securityPolicy(), element: <SecurityPolicy /> },
    { path: Paths.health(), element: <Healthcheck /> },
    { path: '*', element: <PageNotFound /> },
  ];
};
