import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { WellbeingNav } from './WellbeingNav';
import { Reports } from '../Analytics/Reports/types';
import { AnalyticsParams, AnalyticsService } from '../../services/analytics';
import { useIntegrations } from '../../state/integrations';
import { OverlaySpinner } from '../../components/Spinner';
import { useAccount } from '../../state/account';
import { Paths } from '../../types/paths';
import { theme } from '../../theme';

export const Wellbeing: React.FC = () => {
  const { switchToConsoleAnalytics } = useFlags();
  const { analytics: { url, wellbeingId, externalId } = {} } = useIntegrations();
  const { activeAccountId, activeProjectId } = useAccount();
  const navigate = useNavigate();
  const [isIframeLoading, setIsIframeLoading] = React.useState<boolean>(true);
  const isIlyaTestProject = activeAccountId === '12' && activeProjectId === '105';

  const { isFetching, data } = useQuery<Reports[]>(
    [AnalyticsService.queryKeyDashboards, externalId, wellbeingId],
    ({ signal }) =>
      AnalyticsService.getDashboards(
        {
          projectId: externalId,
          componentId: wellbeingId,
          analyticsUrl: url,
        } as AnalyticsParams,
        signal,
      ),
    { enabled: !!externalId && !!wellbeingId },
  );

  const wellbeingURL = useMemo(
    () => data?.find(item => item.name.toLowerCase() === 'wellbeing team manager dashboard')?.url,
    [data],
  );

  useEffect(() => {
    !isIlyaTestProject
      ? navigate(switchToConsoleAnalytics ? Paths.analytics() : Paths.oldAnalytics(), {
          replace: true,
        })
      : navigate(switchToConsoleAnalytics ? Paths.wellbeing() : Paths.oldWellbeing());
  }, [isIlyaTestProject, switchToConsoleAnalytics]);

  return (
    <>
      <WellbeingNav />
      {wellbeingURL && (
        <iframe
          width="100%"
          style={{ paddingTop: theme.subNavHeight }}
          height="100%"
          src={wellbeingURL}
          onLoad={() => setIsIframeLoading(false)}
        />
      )}
      <OverlaySpinner loading={isFetching || isIframeLoading} />
    </>
  );
};
