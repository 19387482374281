import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { useQueryClient } from '@tanstack/react-query';
import { AuthService } from '../services/auth';
import { LoginFormValues } from '../pages/Login/LoginForm';
import { checkAccountStorage, deleteAuthConfig, getAuthConfig, setAuthConfig } from '../utils/auth';
import { LAST_TIMESTAMP } from '../constants';

interface AuthTokenParsed extends JwtPayload {
  preferred_username: string;
  family_name?: string;
  given_name?: string;
  name?: string;
  sub: string;
}

interface IAuthContext {
  authenticated: boolean;
  authToken: string;
  refreshToken: string;
  username: string;
  fullName: string;
  id: string;
  signIn: (data: LoginFormValues, callback: VoidFunction) => any;
  signOut: (callback?: VoidFunction) => void;
}

interface IState {
  authenticated: boolean;
  authToken: string;
  refreshToken: string;
  tokenParsed: AuthTokenParsed | null;
  username: string;
  fullName: string;
  id: string;
}

const getInitialData = () => {
  const authData = getAuthConfig();
  const tokenParsedInitial = authData && jwt_decode<AuthTokenParsed>(authData.access_token);
  return {
    authenticated: !!authData?.access_token,
    authToken: authData?.access_token || '',
    refreshToken: authData?.refresh_token || '',
    tokenParsed: tokenParsedInitial,
    username: tokenParsedInitial?.preferred_username || '',
    fullName: tokenParsedInitial?.name || '',
    id: tokenParsedInitial?.sub || '',
  };
};

const AuthContext = React.createContext<IAuthContext>({} as IAuthContext);

export const AuthProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [state, setState] = useState<IState>(getInitialData());
  const queryClient = useQueryClient();

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (state.tokenParsed?.exp) {
      const expiresIn = (state.tokenParsed.exp - new Date().getTime() / 1000) * 1000;
      if (expiresIn <= 0) {
        refreshToken();
      } else {
        timer = setTimeout(refreshToken, expiresIn);
      }
    }
    return (): void => clearTimeout(timer);
  }, [state.tokenParsed]);

  useEffect(() => {
    const handleStorageChange = () => {
      const authData = getAuthConfig();
      if (authData?.access_token !== state.authToken) {
        const data = getInitialData();
        setState({ ...data });
      }
    };
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [state.authToken]);

  const signIn = async (data: LoginFormValues, callback: VoidFunction) => {
    const response = await AuthService.login(data);
    if (response?.access_token) {
      const tokenParsed = jwt_decode<AuthTokenParsed>(response.access_token);
      const username = tokenParsed.preferred_username;
      checkAccountStorage(username);
      setState({
        authenticated: true,
        authToken: response.access_token,
        refreshToken: response.refresh_token,
        tokenParsed: tokenParsed,
        username: username,
        fullName: tokenParsed.name || '',
        id: tokenParsed.sub,
      });
      setAuthConfig(response);
      callback();
    }
    return response;
  };

  const signOut = async (callback?: VoidFunction) => {
    await AuthService.logout({ token: state.authToken });
    setState({
      authenticated: false,
      authToken: '',
      refreshToken: '',
      tokenParsed: null,
      username: '',
      fullName: '',
      id: '',
    });
    deleteAuthConfig();
    await queryClient.clear();
    localStorage.removeItem(LAST_TIMESTAMP);
    callback?.();
  };

  const refreshToken = async () => {
    const response = await AuthService.refreshToken({
      refresh_token: state.refreshToken,
      grant_type: 'refresh_token',
    });
    if (response?.access_token) {
      const tokenParsed = jwt_decode<AuthTokenParsed>(response.access_token);
      setState({
        authenticated: true,
        authToken: response.access_token,
        refreshToken: response.refresh_token,
        tokenParsed: tokenParsed,
        username: tokenParsed.preferred_username,
        fullName: tokenParsed.name || '',
        id: tokenParsed.sub,
      });
      setAuthConfig(response);
    } else {
      signOut();
    }
  };

  const value = useMemo(() => ({ ...state, signIn, signOut }), [state]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export function useAuth(): IAuthContext {
  return useContext(AuthContext);
}

export function RequireAuth({ children }: { children: JSX.Element }): JSX.Element {
  const location = useLocation();
  const { authenticated } = useAuth();
  if (!authenticated) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
}
